import React from 'react'
import { Card, Text, Link, IconButton, Divider } from 'theme-ui'
import Section from '@components/Section'
import { Link as GLink } from 'gatsby'
import useSiteMetadata from '@helpers/useSiteMetadata'
import { FaPhone, FaEnvelope, FaLocationArrow } from 'react-icons/fa'

  
const Commitment = () => {

  const { phone, address, email } = useSiteMetadata()

  return (
<Section aside title='Redakcja'>
    <Card variant='paper'>
      <Text variant='p'>
        <strong>Redaktor naczelny</strong>
      </Text>
      <Text variant='p'>
      <Link variant='mute' as={GLink} to="../author/magda-kwiatkowska/">
        Magda Kwiatkowska
      </Link>
    </Text>
      <Text variant='p'>
        <b>Z-ca redaktora naczelnego</b>
      </Text>
      <Link variant='mute' as={GLink} to="../author/patrycja-zalewska/">
        Patrycja Zalewska
      </Link>
      <Divider />
      {phone && (
          <Text>
            <IconButton variant='simple' role='presentation'>
              <FaPhone />
            </IconButton>
            {phone}
          </Text>
        )}
        {email && (
          <Text>
            <IconButton variant='simple' role='presentation'>
              <FaEnvelope />
            </IconButton>
            {email}
          </Text>
        )}
        {address && (
          <Text>
            <IconButton variant='simple' role='presentation'>
              <FaLocationArrow />
            </IconButton>
            {address}
          </Text>
        )}
    </Card>

  </Section>
  )
  
  }

export default Commitment
