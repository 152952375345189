import React from 'react'
import { Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
/* import ContactInfo from '@widgets/ContactInfo' */
import Commitment from '@widgets/Commitment'

export default () => (
  <>
    <Seo title='Kontakt' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Skontaktuj się z nami"
          subheader='Zapraszamy do kontaktu z nami za pośrednictwem adresu e-mail redakcja@wparagrafie.pl lub skorzystania z poniższego formularza.'
        />
        <Divider />
        <ContactForm />
      </Main>
      <Sidebar>
        <Commitment />
        <Divider />
        {/* <ContactInfo /> */}
      </Sidebar>
    </Stack>
  </>
)
